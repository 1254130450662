import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHERE CAN I JOIN TO VIEW AND DISCUSS STRATEGIES?" >
  We have vibrant discord community, you are welcome to join and enjoy the discussions.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="IS AGE OF CLASHES FREE TO PLAY?" >
  ge of Clashes is designed to provide a strategic experience for everyone without a pay-to-win play style. It is free to play but will have optional card pack purchases for those who want to explore more diverse strategies as part of their arsenal.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="HOW DOES MULTIPLAYER WORK IN AGE OF CLASHES?" >
  Age of Clashes  features real-time multiplayer battles. You can challenge friends or join random matches to test your skills against other players.
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="HOW CAN I REPORT A BUG OR PROVIDE FEEDBACK?" >
You can send them to lekhaj@rpgamesstudio.com and also join in our discord and share your feedback. We will strive to provide in game option for this making easier for players to provide their feedback adn report bugs.Your input is valuable for improving the game.

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="CAN I PLAY AGE OF CLASHES OFFLINE?
" >
Age of Clashes requires an internet connection to play, as it is focused on multiplayer battles.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="ARE THERE REGULAR UPDATES AND NEW CLANS FOR AGE OF CLASHES?
" >
Yes, we strive to provide regular updates with new features, clans, game modes, improvements, and exciting content to keep the gaming experience fresh and engaging.
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq